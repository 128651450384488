<template>
  <!--
    The view for the startFinishDetails-component
  -->
  <StartFinishDetails
    :installation-id="installationId"
    :lane-number="laneNumber"
  />
</template>

<script>
export default {
  name: "StartFinishDetailsView",
  components: {
    StartFinishDetails: () => import('@/components/StartFinish/StartFinishDetails.vue'),
  },
  props: {
    installationId: {
      type: String,
      required: true
    },
    laneNumber: {
      type: String,
      required: false,
      default () {
        return null;
      }
    }
  },
  metaInfo () {
    return {
      title: this.$t('startFinish')
    }
  }
}
</script>
